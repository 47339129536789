import { useEffect, useState,useRef,useMemo } from "react";
import "./index.less";
import { Button, Modal, Input, Select, Checkbox, message, Form, Radio } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SupersetClient } from "@superset-ui/core";
import { CheckboxOptionType } from "antd/es/checkbox";
import rison from "rison";
import {  useHistory } from 'react-router-dom';
import SaveCard from 'src/assets/images/icons/saveCard.svg';

interface aiDialogProps {
  defaultName: string;
  isShow: boolean;
  cancelShow: () => void;
  extraData: any
}
const CheckboxGroup = Checkbox.Group;
const host = `${window.location.host}/extra_server/`;
type LayoutType = Parameters<typeof Form>[0]['layout'];
const AiDialog = ({ defaultName ,isShow, cancelShow, extraData }: aiDialogProps) => {
  const [form] = Form.useForm();
  const history=useHistory()
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const [dashboardOptions, setDashboardOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [chartName, setChartName] = useState<string>('');
  const [dashboardId, setDashboardId] = useState<number>('');
  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };
  useEffect(() => { 
    form.resetFields();
    setChartName(defaultName)
  }, [isShow])
  useEffect(() => {
    setLoading(true)
    const filters = {
      filters: [
        {
          col: 'dashboard_title',
          opr: 'title_or_slug',
          value: searchKey,
        },
      ],
    };
    const queryParams = rison.encode({
      page_size: 25,
      ...filters,
    });
    SupersetClient.get({
      endpoint: `/api/v1/dashboard/?q=${queryParams}`
    }).then(ret => {
      if (ret.json) {
        setLoading(false)
        const { result } = ret.json;
        const dashboardOptions = result.map((item: any) => ({
            value: item.id, label: item.dashboard_title
          }));
        setDashboardOptions(dashboardOptions);
      }
    })
      .catch(err => {
        console.log(err);
      });
  }, [searchKey]);
  const handleSaveCard = async (gotodash:boolean) =>{
    await form.validateFields();
    if (gotodash&&!dashboardId) { 
      return
    }
    SupersetClient.post({
      host,
      endpoint: `/chartManager/saveChart`,
      mode: 'cors',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        "chartName": chartName,
        "extraData": extraData,
        dashboardId
      }),
      parseMethod: 'json',
    })
      .then(({ json }) => {
        message.success('保存成功')
        cancelShow()
        if (gotodash) {
          if (!dashboardId) {
            return
          }
         history.push(`/superset/dashboard/${dashboardId}`)
        }
      })
      .catch(err => console.log(err));
  }

  return (
    <Modal title="保存图卡" width={471} visible={isShow} onCancel={cancelShow} footer={null} wrapClassName="aiDialog" >
      <div className="aiSave">
        <div className="tips"><SaveCard />保存后可以在
          <span>图卡</span>
          页面里查看和管理</div>
        <Form
          layout={formLayout}
          form={form}
          initialValues={{ layout: 'Vertical' }}
          style={{ maxWidth: formLayout === 'inline' ? 'none' : 600,height:'190px' }}
        >
          {/* <Form.Item label="">
            <Radio.Group>
              <Radio value={1}>保存</Radio>
              <Radio value={2}>保存（另存为）</Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item name="chartName" initialValue={defaultName} label="图卡名称" rules={[{ required: true,message:'图卡名称必填' }]}>
            <Input size="small" placeholder="" value={chartName} onChange={(e) => { setChartName(e.target.value) }} />
          </Form.Item>
          <Form.Item name="dashboardId" label="添加到看板">
            <Select
              allowClear
              loading={loading}
              size="small"
              options={dashboardOptions}
              showSearch
              placeholder="选择一个现有的看板或新建一个"
              filterOption={false}
              onSearch={(e: any) => {
                setSearchKey(e)
              }}
              onChange={(e: any) => { setDashboardId(e) }}
            />
          </Form.Item>
        </Form>
        <div className="footer">
          <div className="footer_btnGroup">
            <div className="saveAndTo" onClick={cancelShow}>取消</div>
            <div style={{ cursor: !dashboardId ? 'not-allowed' : 'pointer' }} className={!dashboardId?"btnDisabled":"saveAndTo"} onClick={()=>handleSaveCard(true)}>保存并前往看板</div>
            <div className="save" onClick={()=>handleSaveCard(false)}>保存</div>
          </div>
        </div>
      </div>
    </Modal >

  )
}
export default AiDialog;