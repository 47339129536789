import { GptsItemType } from "../types/data";

/** AI主应用的assistantId */
const mainAssistantId = 'AI分析教练';
const aiSmallAppId = 'smallApp';
/** 支持@群聊小应用的assistantId */
const aiGroupChatId = 'multi_agent_arch_A';

/** 创建新图卡的assistantId */
const newChartAssistantId = 'asst_iA0wXzkIgriNqDZaDmU8nTwV';

const oldMainAssistantId = 'asst_JizPsEs5oCwxHf3bVyIeYckP';

/** AI小应用列表 */
const gptsList: Array<GptsItemType> = [
  {
    title: 'Assistant',
    content: '',
    assistantId: oldMainAssistantId,
  },
  {
    title: '快问快答',
    content: "我是您的高效查询引擎，'一键快速'提取所需数据。请注意，我无法记忆过往的对话内容。",
    assistantId: 'datawake_query_engine',
  },
  {
    title: '#广告 否定关键词识别',
    content: '只需提供ASIN或店铺名称，我即可为您分析并确定哪些关键词需要加否定',
    assistantId: 'asst_8ClNTen8wCLSlN3oUGFPjyvE',
    starters: [
      '分析某个[ASIN]的关键词广告投放，识别需要加否定的关键词',
      '分析当下店铺所有关键词广告投放，识别需要加否定的关键词'
    ],
  },
  {
    title: '认证图卡检索——知识库验证',
    content: '从经过认证的知识库里找寻你问题的准确回答',
    assistantId: 'asst_OlQTEYam5yj68mnQxK4qzn9c',
  },
  {
    title: 'AI问答抢先体验版',
    content: '',
    assistantId: 'asst_BMF4Gqb0c4ezKX1KItCuX7jb',
  },
  {
    title: 'AI商业分析师(概念验证)',
    content: '通过启发式提问，完成数据驱动的决策',
    assistantId: 'asst_2Xk5f9LeItudZK3bzwvVTkhb',
  },
  {
    title: '指标口径解释',
    content: '',
    assistantId: 'asst_LXJso5J6MV64IJ7PNOyK9B9v',
  },
  {
    title: '创建新图卡',
    content: '帮您创建新图卡并列明涉及的指标计算口径',
    assistantId: newChartAssistantId,
  },
];

/** 群聊小应用列表 */
const groupChatAssistantList = [
  '细分市场TOP10商品卖点提炼',
  '玩转ABA-分析搜索词',
  '玩转ABA-反查搜索词',
  '玩转ABA-通过模板寻找潜力市场',
  '否定关键词识别教练',
  '提炼关键词',
  '优化Listing_标题',
];

export {
  mainAssistantId,
  aiSmallAppId,
  gptsList,
  newChartAssistantId,
  aiGroupChatId,
  groupChatAssistantList,
};
