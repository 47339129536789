const LOCAL_SHOP_ACCOUNT_NAME = '__shop_account_name__';

const SHOP_ACCOUNT_NAME_KEY = 'shop-name';

const getLocalShopAccountName = () => {
  return window.localStorage.getItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const setLocalShopAccountName = (shopAccountName: string) => {
  window.localStorage.setItem(LOCAL_SHOP_ACCOUNT_NAME, shopAccountName);
};

const clearLocalShopAccountName = () => {
  window.localStorage.removeItem(LOCAL_SHOP_ACCOUNT_NAME);
};

const COUNTRY_NAME_KEY = 'country-name';

export {
  getLocalShopAccountName,
  setLocalShopAccountName,
  SHOP_ACCOUNT_NAME_KEY,
  COUNTRY_NAME_KEY,
  clearLocalShopAccountName,
};
