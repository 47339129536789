import { AdTypeTargetingType, AdTypeTargetingValue } from "../../RightContent/AutoRule/AppRule/type";

interface Options {
  label: string;
  value: AdTypeTargetingValue;
}

export const adTypeTargetingOptions: Options[] = [
  {
    value: 'SP-AUTO',
    label: '商品推广广告-自动'
  },
  {
    value: 'SP-MANUAL',
    label: '商品推广广告-手动'
  },
  {
    value: 'SB',
    label: '品牌广告'
  },
  {
    value: 'SD',
    label: '展示型广告'
  },
  {
    value: 'ST',
    label: 'Sponsored TV'
  },
];

export const adTypeTargetingTypeMap: Record<AdTypeTargetingValue, AdTypeTargetingType> = {
  'SP-AUTO': {
    ad_type: 'SP',
    targeting_type: 'AUTO'
  },
  'SP-MANUAL': {
    ad_type: 'SP',
    targeting_type: 'MANUAL'
  },
  'SB': {
    ad_type: 'SB',
  },
  'SD': {
    ad_type: 'SD',
  },
  'ST': {
    ad_type: 'ST',
  },
}
