/** @format */

import { createSlice, createAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ERROR_TEXT } from 'src/constants';
import { AiAnalyseState, ChatHistoryList, EditChatType, RootState, SelectFile } from 'src/dashboard/types';
import httpRequest from 'src/utils/httpRequest';
import { mainAssistantId } from './utils/gptsConfig';
import { getAiWebSocket } from './webSocket/connect';
import { isDemoAccount } from 'src/utils/demoHelper';
import { MessageObject } from './types/data';

const initialState: AiAnalyseState = {
  chatList: [],
  chatType: {},
  chatHistoryList: [],
  selectChatId: '',
  selectChatWsId: '',
  favoriteList: [],
  problemsList: [],
  labelList: [],
  isOpenGuide: false,
  chatInputValue: '',
  isChatting: false,
  assistantId: mainAssistantId,
  selectFileKeys: [],
  datasetDict: {},
  curChatAssistantId: undefined,
};

/** 对话流记录 */
export const getChatList = (state: RootState) => state.aiAnalyse.chatList;
/** 对话类型 */
export const getChatType = (state: RootState) => state.aiAnalyse.chatType;
/** 对话历史记录列表 */
export const getChatHistoryList = (state: RootState) => state.aiAnalyse.chatHistoryList;
/** 选中的对话历史记录id */
export const getSelectChatId = (state: RootState) => state.aiAnalyse.selectChatId;
/** 选中的对话历史记录websocket上的chat_id（首次对话时为假id） */
export const getSelectChatWsId = (state: RootState) => state.aiAnalyse.selectChatWsId;
/** 用户收藏的问题列表 */
export const getFavoriteList = (state: RootState) => state.aiAnalyse.favoriteList;
/** 所有问题列表 **/
export const getProblemsList = (state: RootState) => state.aiAnalyse.problemsList;
/** 获取所有标签 **/
export const getLabelList = (state: RootState) => state.aiAnalyse.labelList;
/** 是否打开引导弹框 */
export const getIsOpenGuide = (state: RootState) => state.aiAnalyse.isOpenGuide;
/** 输入对话框的文本 */
export const getChatInputValue = (state: RootState) => state.aiAnalyse.chatInputValue;
/** 是否在对话中 */
export const getIsChatting = (state: RootState) => state.aiAnalyse.isChatting;
/** assistantId */
export const getAssistantId = (state: RootState) => state.aiAnalyse.assistantId;
/** 引用的文件列表 */
export const getSelectFileKeys = (state: RootState) => state.aiAnalyse.selectFileKeys;
/** 数据集字典 */
export const getDatasetDict = (state: RootState) => state.aiAnalyse.datasetDict;
/** 编辑对话 */
export const getEditChat = (state: RootState) => state.aiAnalyse.editChat;
/** 获取当轮对话的小应用id（群聊） */
export const getCurChatAssistantId = (state: RootState) => state.aiAnalyse.curChatAssistantId;


/**
 * 获取对话历史记录列表
 */
export const getChatHistoryListRequest = createAsyncThunk('aiAnalyes/getChatHistoryListRequest', async () => {
  const { json } = await httpRequest.get('/chat/list');
  return json.ret.sort((a: any, b: any) => b.createTime - a.createTime) || [];
});

/**
 * 新增对话
 */
export const addChatHistory = createAsyncThunk('aiAnalyes/addChatHistory', async (title: string, { dispatch }) => {
  const { json } = await httpRequest.post('/chat/add', { title });
  dispatch(getChatHistoryListRequest());
  return json.ret;
});

/**
 * 删除对话
 */
export const deleteChatHistoryById = createAsyncThunk('aiAnalyes/deleteChatHistoryById', async (id: number, { dispatch }) => {
  const { json } = await httpRequest.delete(`/chat/${id}/delete`);
  dispatch(getChatHistoryListRequest());
  return json.ret;
});

/**
 * 编辑对话
 */
export const updateChatHistoryById = createAsyncThunk('aiAnalyes/updateChatHistoryById', async (item: ChatHistoryList, { dispatch }) => {
  const { json } = await httpRequest.put('/chat/update', item);
  dispatch(getChatHistoryListRequest());
  return json.ret;
});

/**
 * 获取记录对话记录
 */
export const getHistoryChatByChatId = createAsyncThunk('aiAnalyes/getHistoryChatByChatId', async (chatId: string) => {
  const { json } = await httpRequest.get(`/chat/${chatId}/history`);
  const chatList: any[] = []
  const historyChatList = json.ret;
  historyChatList?.forEach((item: any) => {
    chatList.push({ replyMsg: item.question, inputFiles: item.answerData?.inputFiles, position: 'right' });
    chatList.push({ ...item, ...item.answerData, position: 'left', webSocketAnswerData: JSON.parse(item.webSocketAnswerData), msgId: item.messageId, isLoading: false });
  });
  return chatList;
});

/**
 * AI问答对话，获取单条AI对话结果 - mongoChat版
 */
export const getNewMongoChatData = createAsyncThunk('aiAnalyes/getNewMongoChatData', async (msg: string, { getState, dispatch }) => {
  const state = getState() as RootState;
  const isChatting = getIsChatting(state);
  if (isChatting) {
    return;
  }
  dispatch(updateIsChatting(true));
  const chatType = getChatType(state);
  const chatId = getSelectChatId(state);
  const assistantId = getAssistantId(state);
  const selectFileKeys = getSelectFileKeys(state);
  const curChatAssistantId = getCurChatAssistantId(state);
  // 发送前置空选中的文件列表
  dispatch(deleteAllSelectFileKeys());
  // 今后全部使用websocket对话
  // if (assistantId === mainAssistantId) {
  // 发送websocket消息
  getAiWebSocket().sendMessage({
    data: {
      chatId,
      datasetId: '12',
      msg,
      command: chatType?.command,
      assistantId,
      inputFiles: selectFileKeys,
      isDemo: isDemoAccount(),
      at: curChatAssistantId // 群聊时需要@的小应用id
    },
    type: 'request',
    chatId,
  })
  return null;
  // }
  // const chatList = getChatList(state);
  // const { json } = await httpRequest.post('/copilot/mongoChat', {
  //   chatId,
  //   datasetId: '12',
  //   msg,
  //   command: chatType?.command,
  //   assistantId,
  //   inputFiles: selectFileKeys,
  // })
  // if (!chatId) {
  //   // 首轮对话无chatId则会新增一条对话列表需同步更新
  //   dispatch(getChatHistoryListRequest());
  // }
  // const { length } = chatList;
  // const last = chatList[length - 1];
  // if (json.status === 0) {
  //   const oldData = chatList.slice(0, -1);
  //   oldData.push({ ...last, ...json.ret, isLoading: false, question: msg });
  //   return oldData;
  // }
  // console.log('getNewMongoChatData-status非0: ', json);
  // const lastTwo = chatList[length - 2];
  // const oldData = chatList.slice(0, -2);
  // // 报错时的处理
  // oldData.push({ ...lastTwo, error: true });
  // oldData.push({ ...last, error: true, isLoading: false });
  // message.error(ERROR_TEXT);
  // return oldData;
});


/**
 * AI问答对话，获取单条AI对话结果 - 弃用可忽略
 */
export const getNewChatData = createAsyncThunk('aiAnalyes/getNewChatData', async (msg: string, { getState, dispatch }) => {
  const state = getState() as RootState;
  const chatType = getChatType(state);
  const chatList = getChatList(state);
  // // 判断如果是新对话则新增对话记录
  // if (chatList.length <= 2) {
  //   // TODO 等接口能接入后修改成chatId判断&获取
  //   await dispatch(addChatHistory(msg));
  // }
  const chatInfo = chatList.filter((item) => item.position === 'left');
  let chatId = '';
  if (chatInfo.length > 0) {
    chatId = chatInfo[chatInfo.length - 1].chatId || '';
  }
  
  const { json } = await httpRequest.post('/copilot/chatv2', {
    chatId,
    datasetId: '12',
    msg,
    command: chatType?.command,
  })
  //
  if (!chatId) {
    // 首轮对话无chatId则会新增一条对话列表需同步更新
    dispatch(getChatHistoryListRequest());
  }
  const { length } = chatList;
  const last = chatList[length - 1];
  if (json.status === 0) {
    const oldData = chatList.slice(0, -1);
    oldData.push({ ...last, ...json.ret, isLoading: false });
    return oldData;
  }
  const lastTwo = chatList[length - 2];
  const oldData = chatList.slice(0, -2);
  // 报错时的处理
  oldData.push({ ...lastTwo, error: true });
  oldData.push({ ...last, error: true, isLoading: false });
  message.error(json.msg);
  return oldData;
});

/**
 * 获取用户的收藏列表
 */
export const getProblemsFavoriteList = createAsyncThunk('aiAnalyes/getProblemsFavoriteList', async () => {
  const { json } = await httpRequest.get('/problems/favorite/list');
  return json.ret || [];
});

export const getCommandData = createAsyncThunk('aiAnalyes/getCommandData', async () => {
  const { json } = await httpRequest.post('/copilot/getCommands')
  if (json.status === 0) {
    return json.ret[0]
  }
  message.info(json.msg);
  return '';
});

/**
  * @desc 获取所有问题列表
  * @date 2024/01/06
  */
export const getAllProblemsList = createAsyncThunk('aiAnalyes/getAllProblemsList', async () => {
  const { json } = await httpRequest.get('/problems/list');
  return json.ret || [];
});

/**
  * @desc 获取所有标签
  * @date 2024/01/06
  */
export const getAllLabelList = createAsyncThunk('aiAnalyes/getAllLabelList', async () => {
  const { json } = await httpRequest.get('/problems/labels');
  return json.ret || [];
});

/**
 * 点赞或点踩，返回值代表请求是否成功
 * @param param0 
 * @returns 
 */
export const updateIsLike = async ({
  chatId,
  comment,
  like,
  type,
  msgId,
}: {
  chatId: string,
  comment: string,
  like: boolean,
  type: string,
  msgId: string,
}) => {
  try {
    await httpRequest.post('/copilot/like', {
      chatId,
      comment,
      like,
      type,
      msgId,
    });
    return true;
  } catch (error) {
    return false
  }
}

const resetModalAction = createAction('aiAnalyse/reset-action');

export const aiAnalyseSlice = createSlice({
  name: 'aiAnalyse',
  initialState,
  reducers: {
    addChatList: (state, action: PayloadAction<string>) => {
      if (state.isChatting) {
        return;
      }
      state.chatList = [
        ...state.chatList,
        { position: 'right', replyMsg: action.payload, inputFiles: state.selectFileKeys },
        { position: 'left', replyMsg: '正在查询中，请稍后...', isLoading: true, webSocket: true, webSocketAnswerData: [] }
      ];
    },
    setChatList: (state, action: PayloadAction<any[]>) => {
      const newChat = action.payload;
      state.chatList = newChat;
    },
    setLastChatListError: (state) => {
      if (state.chatList.length === 0) {
        return;
      }
      message.error(ERROR_TEXT);
      const { length } = state.chatList;
      const last = state.chatList[length - 1];
      const lastTwo = state.chatList[length - 2];
      const oldData = state.chatList.slice(0, -2);
      // 请求失败时的处理
      oldData.push({ ...lastTwo, error: true });
      oldData.push({ ...last, error: true, isLoading: false});
      state.chatList = oldData;
      state.isChatting = false;
    },
    /** 切换对话记录 */
    updateSelectChatId: (state, action: PayloadAction<string>) => {
      state.selectChatId = action.payload;
      state.selectChatWsId = action.payload;
      state.isChatting = false;
      // 切换对话需要清空输入框
      state.chatInputValue = '';
      state.curChatAssistantId = undefined;
      if (state.selectFileKeys.length > 0) {
        // 切换对话前有引用的文件则需置空
        state.selectFileKeys = [];
      }
    },
    addTempChatHistoryList: (state, action: PayloadAction<string>) => {
      const newSelectChatId = action.payload;
      if (!newSelectChatId) {
        return;
      }
      if (!state.chatHistoryList.find(i => i.chatId === newSelectChatId)) {
        state.chatHistoryList = [
          {
            createTime: new Date().getTime(),
            id: 0,
            title: state.chatList[0].replyMsg,
            chatId: action.payload,
            assistantId: state.assistantId,
          },
          ...state.chatHistoryList,
        ];
      }
      state.selectChatId = newSelectChatId;
      state.selectChatWsId = newSelectChatId;
    },
    updateTempChatHistoryChatId: (state, action: PayloadAction<string>) => {
      const newSelectChatId = action.payload;
      if (!newSelectChatId || state.selectChatId === newSelectChatId) {
        return;
      }
      state.chatHistoryList = state.chatHistoryList.map((i) => {
        if (i.chatId === state.selectChatId) {
          return {
            ...i,
            title: state.chatList[0].replyMsg,
            chatId: newSelectChatId,
          };
        }
        return i;
      });
      state.selectChatId = newSelectChatId;
    },
    updateIsOpenGuide: (state, action: PayloadAction<boolean>) => {
      state.isOpenGuide = action.payload;
    },
    updateChatInputValue: (state, action: PayloadAction<string>) => {
      state.chatInputValue = action.payload;
    },
    updateIsChatting: (state, action: PayloadAction<boolean>) => {
      state.isChatting = action.payload;
    },
    updateAssistantId: (state, action: PayloadAction<string>) => {
      state.assistantId = action.payload;
    },
    addSelectFileKey: (state, action: PayloadAction<SelectFile>) => {
      const newFileKey = action.payload;
      if (state.selectFileKeys.find(i => i.fileKey=== newFileKey.fileKey)) {
        return;
      }
      state.selectFileKeys = [...state.selectFileKeys, newFileKey];
    },
    deleteSelectFileKey: (state, action: PayloadAction<string>) => {
      const deleteFileKey = action.payload;
      state.selectFileKeys = state.selectFileKeys.filter(i => i.fileKey !== deleteFileKey);
    },
    deleteAllSelectFileKeys: (state) => {
      state.selectFileKeys = [];
    },
    updateDatasetDict: (state, action: PayloadAction<{ [key: string | number]: any }>) => {
      state.datasetDict = {
        ...state.datasetDict,
        ...action.payload
      };
    },
    updateEditChat: (state, action: PayloadAction<EditChatType | undefined>) => {
      state.editChat = action.payload;
    },
    updateChatMessageLoading: (state, action: PayloadAction<{ chatId: string, msgId: string, isMustLoading: boolean }>) => {
      const { chatId, msgId, isMustLoading } = action.payload;
      state.chatList = state.chatList.map((i) => {
        if (i.msgId !== msgId || i.chatId !== chatId) {
          return i;
        }
        return {
          ...i,
          isMustLoading
        };
      });
    },
    updateChatMessage: (state, action: PayloadAction<{ chatId: string, msgId: string, webSocketAnswerData: MessageObject[] }>) => {
      const { chatId, msgId, webSocketAnswerData } = action.payload;
      const newChatList = state.chatList.map((i) => {
        if (i.msgId === msgId && i.chatId === chatId) {
          return {
            ...i,
            webSocketAnswerData,
            isMustLoading: false
          };
        }
        return i;
      });
      state.chatList = newChatList;
    },
    updateCurChatAssistantId: (state, action: PayloadAction<string | undefined>) => {
      state.curChatAssistantId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetModalAction, () => initialState)
      .addCase(getNewChatData.fulfilled, (state, action) => {
        state.chatList = action.payload;
      })
      .addCase(getNewChatData.rejected, (state) => {
        message.error(ERROR_TEXT);
        const { length } = state.chatList;
        const last = state.chatList[length - 1];
        const lastTwo = state.chatList[length - 2];
        const oldData = state.chatList.slice(0, -2);
        // 请求失败时的处理
        oldData.push({ ...lastTwo, error: true });
        oldData.push({ ...last, error: true });
        state.chatList = oldData;
      })
      .addCase(getNewMongoChatData.fulfilled, (state, action) => {
        const result = action.payload;
        if (!result) {
          return;
        }
        // const curChatId = result[1]?.chatId || '';
        // if (!state.selectChatId) {
        //   // 发起新的一轮对话选中新的对话记录
        //   state.selectChatId = curChatId;
        // } else if (state.selectChatId !== curChatId) {
        //   // 已经切换到其他对话记录了
        //   return;
        // }
        // state.chatList = result;
        // state.isChatting = false;
      })
      .addCase(getNewMongoChatData.rejected, (state, action) => {
        console.log('getNewMongoChatData-error: ', action.error);
        
        state.isChatting = false;
        message.error(ERROR_TEXT);
        const { length } = state.chatList;
        const last = state.chatList[length - 1];
        const lastTwo = state.chatList[length - 2];
        const oldData = state.chatList.slice(0, -2);
        // 请求失败时的处理
        oldData.push({ ...lastTwo, error: true });
        oldData.push({ ...last, error: true, isLoading: false });
        state.chatList = oldData;
      })
      .addCase(getCommandData.fulfilled, (state, action) => {
        state.chatType = action.payload;
      })
      .addCase(getCommandData.rejected, () => {
        message.error(ERROR_TEXT);
      })
      .addCase(getProblemsFavoriteList.fulfilled, (state, action) => {
        state.favoriteList = action.payload;
      })
      .addCase(getAllProblemsList.fulfilled, (state, action) => {
        state.problemsList = action.payload;
      })
      .addCase(getAllLabelList.fulfilled, (state, action) => {
        state.labelList = action.payload;
      })
      .addCase(getChatHistoryListRequest.fulfilled, (state, action) => {
        state.chatHistoryList = action.payload;
      })
      .addCase(getChatHistoryListRequest.rejected, (state) => {
        state.chatHistoryList = [];
      })
      .addCase(addChatHistory.fulfilled, (state, action) => {
        state.selectChatId = action.payload.id;
      })
      .addCase(getHistoryChatByChatId.fulfilled, (state, action) => {
        state.chatList = action.payload;
      })
      .addCase(getHistoryChatByChatId.rejected, (state, action) => {
        console.error('getHistoryChatByChatId-rejected', action);
        state.chatList = [];
      })
  },
});

export const {
  addChatList,
  setChatList,
  updateSelectChatId,
  updateIsOpenGuide,
  updateChatInputValue,
  updateIsChatting,
  updateAssistantId,
  addSelectFileKey,
  deleteSelectFileKey,
  deleteAllSelectFileKeys,
  addTempChatHistoryList,
  updateTempChatHistoryChatId,
  setLastChatListError,
  updateDatasetDict,
  updateEditChat,
  updateChatMessageLoading,
  updateChatMessage,
  updateCurChatAssistantId,
} = aiAnalyseSlice.actions;

export { resetModalAction };

export default aiAnalyseSlice.reducer;
