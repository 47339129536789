/** @format */

import { createSlice, createAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { InspectionState, RootState, } from 'src/dashboard/types';
import httpRequest from 'src/utils/httpRequest';

const initialState: InspectionState = {
  userData:[]
};

/** 用户信息 */
export const getRecUser = (state: RootState) => state.inspection.userData;


/**
 * AI问答对话，获取单条AI对话结果 - 弃用可忽略
 */
export const getUserData = createAsyncThunk('inspection/getUserData', async () => {
  const { json } = await httpRequest.get('/monitor/user/info')
    return json.ret||{};
});


export const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(getUserData.rejected, (state) => {
        state.userData = [];
      })
  },
});


export default inspectionSlice.reducer;
