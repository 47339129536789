import { allHttpRequest } from "src/utils/httpRequest";
import { GetAppRuleListParams, updateAppRuleParams, GetFilterDataParams } from "./type";

export const getAppRuleList = async (params: GetAppRuleListParams) => {
  const res = await allHttpRequest.post('/llm_server/ad/rule/application_rule/list', params);
  return res.json;
}

export const updateAppRule = async (params: updateAppRuleParams) => {
  const res = await allHttpRequest.post('/llm_server/ad/rule/campaigns_ext/update', params);
  return res.json;
};

export const getFilterData = async (params: GetFilterDataParams) => {
  const res = await allHttpRequest.post('/llm_server/ad/list/ad_dim_info/distinct', params);
  return res.json;
};
