/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import moment from 'moment';

export const fDuration = function (t1, t2, format = 'HH:mm:ss.SS') {
  const diffSec = t2 - t1;
  const duration = moment(new Date(diffSec));
  return duration.utc().format(format);
};

export const now = function () {
  // seconds from EPOCH as a float
  return moment().utc().valueOf();
};

export const epochTimeXHoursAgo = function (h) {
  return moment().subtract(h, 'hours').utc().valueOf();
};

export const epochTimeXDaysAgo = function (d) {
  return moment().subtract(d, 'days').utc().valueOf();
};

export const epochTimeXYearsAgo = function (y) {
  return moment().subtract(y, 'years').utc().valueOf();
};
export const tranlateDate = val => {
  const translate = {
    minutes: '分钟',
    minute: '分钟',
    seconds: '秒',
    second: '秒',
    hour: '小时',
    hours: '小时',
    day: '天',
    days: '天',
    month: '月',
    months: '月',
    year: '年',
    years: '年',
  };
  const dateList = val.split(' ');
  return `${dateList[0] === 'a' ? '1' : dateList[0]}${
    translate[dateList[1]]
  }前`;
};
