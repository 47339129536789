import { SupersetClient } from "@superset-ui/core"
import httpRequest, { apiHttpRequest } from "src/utils/httpRequest";
import { SaveChatParams } from "../types/data";


const getTokenRequest = async () => {
  const res = await SupersetClient.get({
    endpoint: '/api/v1/me/token/',
  })
  return res.json?.access_token || '';
}

/**
 * websocket保存聊天记录
 * @param body 
 * @returns 
 */
const saveWebSocketChatHistory = async (body: SaveChatParams) => {
  const res = await httpRequest.post('/chat/save-history-record', body)
  return res;
}

const updateWebSocketChatHistory = async (body: SaveChatParams) => {
  const res = await httpRequest.put('/chat/update-history-record', body)
  return res;
}

const getChartData = async (params: any) => {
  const res = await apiHttpRequest.post('/v1/chart/data', params);
  return res.json;
};

export {
  getTokenRequest,
  saveWebSocketChatHistory,
  getChartData,
  updateWebSocketChatHistory,
};