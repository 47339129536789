
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AiHelperState } from 'src/dashboard/types';

const initialState: AiHelperState = {
  isShow: false,
  isNoAuth: false,
};

/** 是否展示AI助理 */
export const getAiHelperIsShow = (state: RootState) => state.aiHelper.isShow;
export const getIsNoAuth = (state: RootState) => state.aiHelper.isNoAuth;

export const resetAiHelperAction = createAction('aiHelper/reset-action');

export const aiHelperSlice = createSlice({
  name: 'aiHelper',
  initialState,
  reducers: {
    updateAiHelperIsShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
    updateIsNoAuth: (state, action: PayloadAction<boolean>) => {
      state.isNoAuth = action.payload;
    },
  },
  extraReducers(builder) {
    builder
    .addCase(resetAiHelperAction, () => initialState)
  }
});

export const {
  updateAiHelperIsShow,
  updateIsNoAuth,
} = aiHelperSlice.actions;

export default aiHelperSlice.reducer;
