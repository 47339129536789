import React, { memo, useMemo } from 'react';
import { Tooltip } from 'antd';
import QuoteFileIcon from 'src/assets/images/icons/quote_file.svg';
import QuoteFileGreyIcon from 'src/assets/images/icons/quote_file_grey.svg';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectFileKey, deleteSelectFileKey, getSelectFileKeys } from '../../slice';
import { SelectFile } from 'src/dashboard/types';
import classNames from 'classnames';

const QuoteFile: React.FC<SelectFile> = (props) => {
  const { fileKey, fileName } = props;
  const dispatch = useDispatch();
  const selectFileKey = useSelector(getSelectFileKeys);

  const onClickAdd = () => {
    dispatch(addSelectFileKey({ fileKey, fileName }));
  }

  const onClickDelete = () => {
    dispatch(deleteSelectFileKey(fileKey));
  }

  const isSelect = useMemo(() => selectFileKey.find(i => i.fileKey === fileKey), [selectFileKey, fileKey]);

  return (
    <Tooltip
      placement="top"
      title={isSelect ? '该图卡已被引用，再次点击可取消' : '查询结果数据文件已保存云端，点击可被引用对其进一步询问'}>
        {isSelect ? <QuoteFileIcon className={styles.quote_file} onClick={onClickDelete} /> : <QuoteFileGreyIcon className={classNames(styles.quote_file, styles.quote_file_grey)} onClick={onClickAdd} />}
    </Tooltip>
  );
}

export default memo(QuoteFile);
