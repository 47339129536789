/** @format */

import axios from 'axios';
import moment from 'moment';
import { createSlice, createAsyncThunk, PayloadAction, createAction } from '@reduxjs/toolkit';
import httpRequest from 'src/utils/httpRequest';
import { RootState } from 'src/dashboard/types';
import { MonitorAsin, MonitorQuery, MonitorSort, MonitorState, MonitorType, MonitorMode, AsinList } from './types';


/** 示例监控ASIN */
export const DEFAULT_ASIN = 'B081BBB8VJ';
export const DEFAUL_KEYWORD = 'crocs';
export const DEFAULT_NUM = 0;
export const defaultAsinList: AsinList =
{
  asinsAndKeywordsCount: { asinNumbers: 1, keywordNumbers: 1 },
  infoList: [
    {
      asin: DEFAULT_ASIN,
      asinNumbers: DEFAULT_NUM,
      icon: '',
      keyword: '',
      keywordNumbers: DEFAULT_NUM,
    },
  ],
};

export const defaultKeywordList: AsinList =
{
  asinsAndKeywordsCount: { asinNumbers: 1, keywordNumbers: 1 },
  infoList: [
    {
      asin: '',
      asinNumbers: 0,
      icon: '',
      keyword: DEFAUL_KEYWORD,
      keywordNumbers: DEFAULT_NUM
    }
  ]
};

const initialState: MonitorState = {
  asinList: {
    infoList: [],
    asinsAndKeywordsCount: {
      asinNumbers: 0,
      keywordNumbers: 0
    }
  },
  asinListLoading: true,
  selectAsin: '',
  selectDate: [moment().subtract(3, 'day').format('yyyy-MM-DD'), moment().format('yyyy-MM-DD')],
  selectType: 'nature',
  selectSort: 'asin',
  monitorData: [],
  monitorDataLoading: true,
  viewType: 'goods',
  showTimeType: '天',
  monitorList: [],
};

/** 获取监控的ASIN列表 */
export const getAsinList = (state: RootState) => state.monitor.asinList;
/** 获取监控的ASIN列表加载状态 */
export const getAsinListLoading = (state: RootState) => state.monitor.asinListLoading;
/** 获取当前选择的ASIN */
export const getSelectAsin = (state: RootState) => state.monitor.selectAsin;
/** 获取当前选择的日期 */
export const getSelectDate = (state: RootState) => state.monitor.selectDate;
/** 获取当前选择的类型 */
export const getSelectType = (state: RootState) => state.monitor.selectType;
/** 获取当前选择的排序 */
export const getSelectSort = (state: RootState) => state.monitor.selectSort;
/** 获取监控数据 */
export const getMonitorData = (state: RootState) => state.monitor.monitorData;
/** 获取监控数据加载状态 */
export const getMonitorDataLoading = (state: RootState) => state.monitor.monitorDataLoading;
/** 获取ASIN视图 */
export const getAsinMode = (state: RootState) => state.monitor.viewType;
/** 获取当前选择的排序 */
export const getSelectTime = (state: RootState) => state.monitor.showTimeType;
/** 根据视图类型获取监控列表 */
export const getMonitorList = (state: RootState) => state.monitor.monitorList;

/**
 * 查询监控的ASIN列表
 */
export const queryAsinList = createAsyncThunk('monitor/queryAsinList', async (_, { getState }) => {
  const state = getState() as RootState;
  const { json } = await httpRequest.get(`/keyword/queryAsinListByViewType?viewType=${state.monitor.viewType}`);
  if (json.status === 0) {
    return json.ret;
  }
  return [];
});

export const queryMonintirListByViewType = createAsyncThunk('monitor/queryMonintirListByViewType', async (payload, { getState }) => {
  const state = getState() as RootState;
  const viewType = getAsinMode(state);
  const asin = getSelectAsin(state);
  if (!asin) {
    return [];
  }
  let url = '';
  const baseParam: any = {};
  if (viewType === 'goods') {
    baseParam.asin = payload;
    url = 'queryKeywordListByAsin';
  }
  if (viewType === 'keyword') {
    baseParam.keyword = payload;
    url = 'queryAsinListByKeyword';
  }

  const { json } = await httpRequest.post(`/keyword/${url}`, baseParam);
  if (json.status === 0) {
    return json.ret;
  }
  return [];
});

/**
 * 查询监控数据
 */
export const queryMonitorData = createAsyncThunk('monitor/queryMonitorData', async (_, { getState }) => {
  const state = getState() as RootState;
  const asin = getSelectAsin(state);
  if (!asin) {
    return [];
  }

  const [startTime, endTime] = getSelectDate(state);
  const rankType = getSelectType(state);
  const rankMode = getSelectSort(state);
  const viewType = getAsinMode(state);
  const showTimeType = getSelectTime(state);
  const current = moment();

  const param: MonitorQuery = {
    startTime: `${startTime} 00:00:00`,
    endTime: current.isSame(endTime, 'day') ? `${endTime} ${current.format('HH:mm:ss')}` : `${endTime} 23:59:59`,
    rankType,
    rankMode,
    showTimeType,
    viewType,
  };

  viewType === 'goods' ? param.asin = asin : param.keyword = asin; // asin和keyword采用不同的查询参数

  const { json } = await httpRequest.post('/keyword/queryDataByViewType', param);
  if (json.status === 0) {
    return json.ret;
  }
  return [];
});

/**
 * 删除监控
 */
export const deteteMonitor = createAsyncThunk('monitor/deteteMonitor', async (params, { getState }) => {
  const state = getState() as RootState;
  const viewType = getAsinMode(state);
  const url = viewType === 'goods' ? 'deleteMonitorByGoods' : 'deleteMonitorByKeyword';
  const { json } = await httpRequest.post(`/keyword/${url}`, params);
  if (json.status === 0) {
    return json.ret;
  }
  return false;
});

/**
 * 下载文件
 */
export const downMonitor = createAsyncThunk('monitor/downMonitor', async (_, { getState }) => {
  const state = getState() as RootState;
  const asin = getSelectAsin(state);
  if (!asin) {
    return [];
  }

  const [startTime, endTime] = getSelectDate(state);
  const rankType = getSelectType(state);
  const rankMode = getSelectSort(state);
  const viewType = getAsinMode(state);
  const showTimeType = getSelectTime(state);
  const current = moment();

  const param: MonitorQuery = {
    startTime: `${startTime} 00:00:00`,
    endTime: current.isSame(endTime, 'day') ? `${endTime} ${current.format('HH:mm:ss')}` : `${endTime} 23:59:59`,
    rankType,
    rankMode,
    showTimeType,
    viewType,
  };
  viewType === 'goods' ? param.asin = asin : param.keyword = asin; // asin和keyword采用不同的查询参数
  const buffer = await axios.post('/extra_server/keyword/downDataByViewType', param, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([buffer.data], { type: buffer.data.type }))
  const a = document.createElement('a');
  const fileNameArr = buffer.headers['content-disposition'].split(";").find(l => l.indexOf('filename=') !== -1);
  let fileName = Date.now() + '.xlsx';
  if (fileNameArr.length) {
    fileName = fileNameArr.split('=')[1].replace(/["']/g, '');
  }
  a.style.display = 'none'
  a.href = url;
  a.setAttribute('download', fileName);
  document.body.appendChild(a)
  a.click() // 执行下载
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a);
  return ''
});

const resetMonitorAction = createAction('monitor/reset-action');

export const monitorSlice = createSlice({
  name: 'aiAnalyse',
  initialState,
  reducers: {
    changeSelectAsin(state, action: PayloadAction<string>) {
      state.selectAsin = action.payload;
    },
    changeSelectDate(state, action: PayloadAction<[string, string]>) {
      state.selectDate = action.payload;
    },
    changeSelectType(state, action: PayloadAction<MonitorType>) {
      state.selectType = action.payload;
    },
    changeSelectSort(state, action: PayloadAction<MonitorSort>) {
      state.selectSort = action.payload;
    },
    changeSelectMode(state, action: PayloadAction<MonitorMode>) {
      state.viewType = action.payload;
    },
    changeSelectTime(state, action: PayloadAction<string>) {
      state.showTimeType = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(resetMonitorAction, () => initialState)
      .addCase(queryAsinList.pending, (state) => {
        state.asinListLoading = true;
      })
      .addCase(queryAsinList.fulfilled, (state, action) => {
        const asinList: AsinList = action.payload;
        state.asinListLoading = false;
        if (!asinList.infoList?.length) {
          state.asinList = state.viewType === 'goods' ? defaultAsinList : defaultKeywordList;
          state.selectAsin = state.viewType === 'goods' ? DEFAULT_ASIN : DEFAUL_KEYWORD;
        } else {
          if (state.viewType === 'goods') {
            state.asinList = asinList;
            state.selectAsin = asinList.infoList[0].asin;
            return;
          }
          if (state.viewType === 'keyword') {
            state.asinList = asinList;
            state.selectAsin = asinList.infoList[0].keyword;
          }
        }
      })
      .addCase(queryAsinList.rejected, (state) => {
        state.asinListLoading = false;
        state.asinList = defaultAsinList;
        state.selectAsin = DEFAULT_ASIN;
      })
      .addCase(queryMonintirListByViewType.fulfilled, (state, action) => {
        const data: MonitorAsin[] = action.payload;
        state.monitorList = data;
      })
      .addCase(queryMonitorData.pending, (state) => {
        state.monitorDataLoading = true;
      })
      .addCase(queryMonitorData.fulfilled, (state, action) => {
        state.monitorData = action.payload;
        state.monitorDataLoading = false;
      })
      .addCase(queryMonitorData.rejected, (state) => {
        state.monitorData = [];
        state.monitorDataLoading = false;
      });
  },
});

export const {
  changeSelectAsin,
  changeSelectDate,
  changeSelectType,
  changeSelectSort,
  changeSelectMode,
  changeSelectTime,
} = monitorSlice.actions;

export {
  resetMonitorAction,
};

export default monitorSlice.reducer;
