import { allHttpRequest } from "src/utils/httpRequest";
import { GetRuleListParams } from "./type";
import { EditRuleContentType } from "../RuleDetail/type";

export const getRuleDataList = async (params: GetRuleListParams) => {
  const res = await allHttpRequest.post('/llm_server/ad/rule/list', params);
  return res.json;
};

export const updateRuleHttp = async (rule: EditRuleContentType) => {
  const res = await allHttpRequest.post('/llm_server/ad/rule/update', rule);
  return res.json;
};

export const deleteRulesHttp = async (ids: number[]) => {
  const res = await allHttpRequest.post('/llm_server/ad/rule/delete', { ids });
  return res.json;
};

export const getShopList = async () => {
  const res = await allHttpRequest.post('/llm_server/ad/get/shop_account_name/');
  return res.json;
};

// export const getShopList = async () => {
//   const { json } = await allHttpRequest.post('/llm_server/data/get_chart_data', {
//     source: {
//       "datasource": {
//           "id": 943,
//           "type": "table"
//       },
//       "force": false,
//       "queries": [
//           {
//               "filters": [],
//               "extras": {
//                   "time_grain_sqla": "P1D",
//                   "having": "",
//                   "where": ""
//               },
//               "applied_time_extras": { },
//               "columns": [
//                   "shop_account_name",
//                   "country_name",
//                   "marketplace_id"
//               ],
//               "metrics": [ ],
//               "orderby": [ ],
//               "annotation_layers": [ ],
//               "row_limit": 1000,
//               "series_limit": 0,
//               "order_desc": true,
//               "url_params": {
//                   "slice_id": "22805"
//               },
//               "custom_params": { },
//               "custom_form_data": { },
//               "post_processing": [ ],
//               "time_offsets": [ ],
//               "comparison_type": null,
//               "offset_metrics_order": null,
//               "filter_nan_offset_metrics": null,
//               "reserve_actual_values": null,
//               "offset_metrics_limit": null,
//               "time_comparison_rolling_type": null,
//               "time_comparison_running_offset": null
//           }
//       ],
//       "result_format": "json",
//       "result_type": "full"
//   }

// });
// return json?.result && json.result[0];
// };
