import React, { useState } from 'react';
import "./index.less";
import { Table, message,ConfigProvider  } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { SupersetClient } from '@superset-ui/core';
import zhCN from 'antd/es/locale/zh_CN';
import SaveIcon from 'src/assets/images/icons/save_small.svg';
import QuoteFile from 'src/pages/AiAnalyse/component/QuoteFile';
import AiDialog from './Dialog/index';

interface CustomCardProps {
    extraData: any;
    question?: string;
    isEdit?: boolean;
    isShowFile?: boolean;
    title?: string;
}

class CustomCard extends React.Component<CustomCardProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isShow: false
        }
    }

    render() {
        const { extraData, isEdit = true, isShowFile, title } = this.props;
        const { isShow } = this.state;
        const handleSaveCard = () => {
            // SupersetClient.post({
            //     host,
            //     endpoint: `/chartManager/saveChart`,
            //     mode: 'cors',
            //     headers: {
            //         Accept: '*/*',
            //         'Content-Type': 'application/json;charset=UTF-8',
            //     },
            //     body: JSON.stringify({
            //         "chartName": question,
            //         "extraData": extraData
            //     }),
            //     parseMethod: 'json',
            // })
            //     .then(({ json }) => { message.success('保存成功') })
            //     .catch(err => console.log(err));
            this.setState({
                isShow: true
            })
        }
        // console.log(extraData.columns.map((cloumn: any) => ({ width:110,title: cloumn, dataIndex: cloumn, key: cloumn, defaultSortOrder: 'ascend', sorter: (a: any, b: any) => a[cloumn] - b[cloumn] })),'aaaaaaaaaaaaaaaaaaa');
        
        const t = title || extraData.title;
        return <div className='custom-wrapper'>
            {
                extraData.columns ?
                <div className='custom-card'>
                    <div className='customcard-title-box'>
                        <span className='customcard-title' title={t}>{t}</span>
                        <div className='customcard-title-operation'>
                            {
                                isShowFile && extraData.fileKey && <QuoteFile fileKey={extraData.fileKey} fileName={t} />
                            }
                                {
                                    isEdit ? <SaveIcon className='save_icon' onClick={() => { handleSaveCard() }} /> : <></>
                                }
                    </div>
                    </div>
                    <ConfigProvider locale={zhCN}>
                    <Table
                        className="mongo-table"
                        showSorterTooltip={false}
                        locale={{
                            cancelSort: '点击取消排序',
                            triggerAsc: '点击升序',
                            triggerDesc:'点击降序'
                        }}
                        size="small"
                        scroll={{  x:true ,y: 160 }}
                        rowClassName={(record, i) => (i % 2 === 1 ? "even" : "odd")}
                            columns={extraData.columns.map((cloumn: any) => {
                                return {
                                    width: 110, title: cloumn, dataIndex: cloumn, key: cloumn,
                                    sorter: (a: any, b: any) => {
                                        // 检查null和undefined的情况
                                        if (a[cloumn] === null || a[cloumn] === undefined) {
                                            return (b[cloumn] === null || b[cloumn] === undefined) ? 0 : -1;
                                        }
                                        if (b[cloumn] === null || b[cloumn] === undefined) {
                                            return 1;
                                        }

                                        // 如果两个值都是数字
                                        if (typeof a[cloumn] === 'number' && typeof b[cloumn] === 'number') {
                                            return a[cloumn] - b[cloumn];
                                        }
                                        // 如果两个值都是字符串
                                        if (typeof a[cloumn] === 'string' && typeof b[cloumn] === 'string') {
                                            return a[cloumn].localeCompare(b[cloumn]);
                                        }
                                        // 如果只有一个是数字，数字排在前面
                                        if (typeof a[cloumn] === 'number') {
                                            return -1;
                                        }
                                        if (typeof b[cloumn] === 'number') {
                                            return 1;
                                        }
                                        // 其他类型的比较
                                        return a[cloumn].toString().localeCompare(b[cloumn].toString());
                                    }
                                }
                            })
                            }
                            dataSource={extraData.results}> </Table>
                        </ConfigProvider>
                </div> : <div style={{ textAlign: 'center' }}>暂无数据</div>
            }
            <AiDialog defaultName={t} isShow={isShow} cancelShow={() => { this.setState({ isShow: false }) }} extraData={extraData}  />
        </div>
    }
}


export default CustomCard;
